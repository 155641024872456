<template>
  <div
    id="map-with-info"
    :class="[
      'container-' + organism.containerWidth,
      organism.hideInfo ? 'hide-info' : '',
      'bg-fr-beige organism map-with-info isolate',
    ]"
  >
    <header class="px-8 pt-14 pb-[69px] lg:-mb-[25px]">
      <h2
        class="text-3xl sm:text-[60px] font-cg text-white font-semibold text-center m-0 leading-none"
      >
        {{ organism.title1 }}
      </h2>
    </header>
    <div
      id="map-wrap-outer"
      class="relative pointer-events-none lg:min-h-[600px]"
    >
      <div
        id="map-wrap"
        class="absolute top-0 left-0 w-full h-full sm:block -z-10 isolate"
      >
        <div id="map" class="w-full h-full pointer-events-auto"></div>
      </div>
      <div v-if="!organism.hideInfo" class="grid grid-cols-1 sm:grid-cols-2">
        <div
          class="bg-white p-8 max-w-[366px] mr-auto sm:mr-0 ml-auto my-8 sm:my-16 pointer-events-auto"
        >
          <div class="mb-4">
            <h3 class="text-lg font-bold font-figtree">Address</h3>
            <NuxtLink
              :to="
                'https://www.google.com/maps/place/' +
                organism.latitude +
                ',' +
                organism.longitude
              "
              target="_blank"
              class="block underline text-bc-dark-blue"
            >
              <span v-if="organism.address1"
                >{{ organism.address1 }}<br
              /></span>
              <span v-if="organism.address2"
                >{{ organism.address2 }}<br
              /></span>
              <span v-if="organism.city">{{ organism.city }},&nbsp;</span
              ><span v-if="organism.state">{{ organism.state }}&nbsp;</span
              ><span v-if="organism.zip">{{ organism.zip }}</span>
            </NuxtLink>
          </div>
          <div v-if="organism.hours" class="mb-4">
            <h3 class="text-lg font-bold font-figtree">Hours</h3>
            <div v-html="organism.hours"></div>
          </div>
          <div v-if="organism.phone" class="mb-4">
            <h3 class="text-lg font-bold font-figtree">Phone</h3>
            <NuxtLink
              :to="'tel:' + organism.phone"
              class="underline text-bc-dark-blue"
              >{{ organism.phone }}</NuxtLink
            >
          </div>
          <div v-if="organism.salesTeamMembers" class="mb-4">
            <h3 class="text-lg font-bold font-figtree">Sales Team</h3>
            <ul
              class="flex flex-wrap items-center justify-center text-center gap-4"
            >
              <li v-for="member in organism.salesTeamMembers">
                <img
                  v-if="member.image"
                  :src="getImageSrc(member.image, 'thumbnail')"
                  :alt="member.name"
                  class="w-[88px] aspect-square rounded-full mb-2"
                  loading="lazy"
                />
                <h4>{{ member.name }}</h4>
              </li>
            </ul>
          </div>
          <NuxtLink
            :to="
              'https://www.google.com/maps/place/' +
              organism.latitude +
              ',' +
              organism.longitude
            "
            target="_blank"
            class="block py-3 mb-4 font-semibold text-center text-white px-7 bg-fr-secondary-dark"
          >
            Get Directions
          </NuxtLink>
          <div class="text-[11px] text-gray-400">{{ organism.legal }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.map-with-info {
  header {
    background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 25px),
      calc(50% - 25px) calc(100% - 25px),
      50% 100%,
      calc(50% + 25px) calc(100% - 25px),
      0 calc(100% - 25px)
    );
  }

  @media screen and (max-width: 1023px) {
    header {
      clip-path: none;
    }
  }
}

.gm-style .gm-style-iw[role="dialog"] {
  @apply p-0 rounded-none;
}

.gm-style .gm-style-iw[role="dialog"] > div {
  @apply !overflow-hidden;
}

.gm-style .gm-style-iw[role="dialog"] > button > span {
  @apply !hidden;
}

.gm-style .gm-style-iw[role="dialog"] > button {
  @apply !bg-gray-900 rounded-full !h-8 !w-8 !top-0 !right-0;
}

.gm-style .gm-style-iw[role="dialog"] > button::before {
  @apply content-[''] bg-[url('/img/cancel-circle.svg')] bg-no-repeat bg-center bg-[length:24px_24px] w-full h-full block;
}

.gm-style .gm-style-iw-tc::after {
  @apply !bg-fr-secondary-dark;
}

.infowindow-content.infowindow-model-homes,
.infowindow-content.infowindow-neighborhoods {
  @apply w-[283px] text-gray-800;
}

.infowindow-content.infowindow-qmi-homes {
  @apply w-[325px] text-gray-800;
}

.infowindow-content img {
  @apply w-full h-[184px] object-cover mb-4;
}

.infowindow-content .title {
  @apply font-figtree text-2xl font-bold text-center mb-3 px-1;
}

.infowindow-content.infowindow-qmi-homes .title {
  @apply mb-0;
}

.infowindow-content .subtitle {
  @apply text-base text-gray-500 font-figtree text-center mb-4 px-1;
}

.infowindow-content .plan {
  @apply font-figtree text-sm font-medium text-bc-wc-green text-center uppercase px-1 mb-3;
}

.infowindow-content .details {
  @apply flex justify-evenly mb-3 px-1;
}

.infowindow-content .details div span {
  @apply block first:text-[22px] last:text-xs last:font-bold text-center font-figtree;
}

.infowindow-content .directions-link {
  @apply block text-bc-dark-blue font-figtree text-base underline text-center flex justify-center gap-1 items-center font-medium mb-4 px-1;
}

.infowindow-content .directions-link::before {
  @apply content-[''] bg-bc-dark-blue bg-[url('/img/arrow-up-right.svg')] bg-no-repeat bg-center bg-[length:8px_8px] w-4 h-4 block rounded;
  clip-path: polygon(
    45% 2%,
    50% 1%,
    55% 2%,
    98% 45%,
    99% 50%,
    98% 55%,
    55% 98%,
    50% 99%,
    45% 98%,
    2% 55%,
    1% 50%,
    2% 45%
  );
}

.infowindow-content .explore-button {
  @apply bg-fr-secondary-dark block text-center text-white font-figtree font-medium text-lg py-[11px] after:content-['\00bb'] after:ml-1 px-1;
}
</style>

<script setup>
import { storeToRefs } from "pinia";
const overview = useOverview();
const { mapInfo } = storeToRefs(overview);

const props = defineProps({
  organism: Object,
});

onMounted(() => {
  loadMapWithInfoMap();
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }

  if (props.organism.mapWithInfoMapType === "all-neighborhoods") {
    loadNeighborhoodsMap();
    const offsetX = props.organism.hideInfo ? 0 : 250;
    updateNeighborhoodsMap(mapInfo);
  } else {
    updateMapWithInfoMap(
      props.organism.latitude,
      props.organism.longitude,
      props.organism.markerTitle
    );
  }
});

onUpdated(() => {
  if (props.organism.mapWithInfoMapType !== "all-neighborhoods") {
    updateMapWithInfoMap(
      props.organism.latitude,
      props.organism.longitude,
      props.organism.markerTitle
    );
  }
});

watch(mapInfo, () => {
  if (props.organism.mapWithInfoMapType === "all-neighborhoods") {
    const offsetX = props.organism.hideInfo ? 0 : 250;
    updateNeighborhoodsMap(mapInfo, offsetX);
  }
  // } else {
  //   updateNeighborhoodsMap(mapInfo, 250);
  // }
});
</script>
